@import '/src/styles/utilities.scss';

.buttonCalendar {
  @include undo-default-button-styles();
  @include primary-stroked-effects();
  @include with-icon();

  height: $button-height-large;
  padding: $button-padding-large;
  font-family: $font-primary;
  font-size: 1.5rem;
  font-weight: $font-weight-bold;
  letter-spacing: -0.3px;
}
