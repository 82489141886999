@import '/src/styles/utilities.scss';

.comicViewer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: $space-36;

  @include media-breakpoint-up(md) {
    gap: $space-44;
  }
}

.comicViewer__comic {
  display: flex;
  flex-direction: column;
  gap: $space-12;

  // ShowComicViewer handles the container on desktop to account for the rail ad
  @include media-breakpoint-down(md) {
    @include create-container();
  }
}
